export default [
    {
    id: 4,
    name: "AI children's chair based on collection of images",
    type: "Stable diffusion",
    inputParams: [{ name: "prompt", default: "children's chair, no humans, white background, simple background, still life, chair, stool, table,  santa hat, <lora:ertongyi:0.8>" },
      { name: "negative_prompt", default: "Embossed, ((bad construction)), words, text, ((distorted structure)), deformedeyes,((disfigured)),((badart)),((deformed)),((extra limbs)),(((duplicate))),((morbid)),((mutilated)),outof frame,extra fingers,mutated hands,poorly drawn eyes,((poorly drawnhands)),((poorlydrawn face)),(((mutation))),((ugly)),blurry,((badanatomy)),(((badproportions))),cloned face,bodyout of frame,out of frame,bad anatomy,gross proportions,(malformedlimbs),((missingarms)),((missinglegs)),(((extraarms))),((extralegs))),(fused fingers),(toomanyfingers),((longneck))),tiling,poorlydrawn,mutated,cross-eye,canvas frame,frame,weird colors,blurry" },
        {name: "sampler_index", default: "DPM++ SDE Karras"},
    ],
    info: "AI generated kids chair based on collection of images",
    endpoint:
      "https://aimodel.dev-metaverse.fun/get_response/v1",
    options: {
      width: { min: 1, max: 1024, default: 512, type: "int" },
      height: { min: 1, max: 1024, default: 768, type: "int" },
      steps: { min: 10, max: 50, default: 20, type: "int" },
      cfg_scale: { min: 0, max: 20, default: 7, type: "float" },
      batch_size: { min: 1, max: 4, default: 1, type: "int" },
    },
  },
  {
    id: 0,
    name: "AI model",
    type: "Stable diffusion",
    inputParams: [{ name: "prompt", default: "masterpiece, best quality, (realistic, photo-realistic:1.4), (RAW photo:1.2), extremely detailed CG unity 8k wallpaper, an extremely delicate and beautiful, amazing, finely detail, official art, absurdres, incredibly absurdres, huge filesize, ultra-detailed, extremely detailed, beautiful detailed girl, extremely detailed eyes and face, beautiful detailed eyes, light on face, 1girl,wearing ((neiyi9090)),  red,sexy,jacquard,thick,  cute, looking at viewer, (perfect female figure), slim waist, (kpop idol), (aegyo sal:1), full body,  standing,  ulzzang-6500-v1.1  <lora:cuteGirlMix4_v10:0.7> <lora:neiyi3:0.9>" },
      { name: "negative_prompt", default: "(worst quality:2), (low quality:2), (normal quality:2), lowres, ((monochrome)), ((grayscale)),paintings, sketches,nipples, skin spots, acnes, skin blemishes, bad anatomy,facing away, looking away,tilted head,lowres,bad anatomy,bad hands, text, error, missing fingers,extra digit, fewer digits, blurry,bad feet,cropped,poorly drawn hands,poorly drawn face,mutation,deformed,worst quality,low quality,normal quality,jpeg artifacts,signature,watermark,extra fingers,fewer digits,extra limbs,extra arms,extra legs,malformed limbs,fused fingers,too many fingers,long neck,cross-eyed,mutated hands,polar lowres,bad body,bad proportions, bad-picture-chill-75v" },
        {name: "sampler_index", default: "DPM++ SDE Karras"},
    ],
    info: "AI model for underwear based on collection of images",
    endpoint:
      "https://aimodel.dev-metaverse.fun/get_response/v1",
    options: {
      width: { min: 1, max: 1024, default: 512, type: "int" },
      height: { min: 1, max: 1024, default: 1024, type: "int" },
      steps: { min: 10, max: 50, default: 20, type: "int" },
      cfg_scale: { min: 0, max: 20, default: 7, type: "float" },
      batch_size: { min: 1, max: 4, default: 1, type: "int" },
    },
  },
  {
    id: 1,
    name: "AI model2",
    type: "Stable diffusion",
    inputParams: [{ name: "prompt", default: "masterpiece, best quality, (realistic, photo-realistic:1.4), (RAW photo:1.2), extremely detailed CG unity 8k wallpaper, an extremely delicate and beautiful, amazing, finely detail, official art, absurdres, incredibly absurdres, huge filesize, ultra-detailed, extremely detailed, beautiful detailed girl, extremely detailed eyes and face, beautiful detailed eyes, light on face, 1girl  wearing an one-piece black ((neiyi9090)), monochrome, underwear, greyscale, bra, looking at viewer, (perfect female figure), slim waist, (kpop idol), (aegyo sal:1), full body,  standing,  ulzzang-6500-v1.1  <lora:cuteGirlMix4_v10:0.7>  <lora:neiyi82:0.7> " },
      { name: "negative_prompt", default: "(worst quality:2), (low quality:2), (normal quality:2), lowres, ((monochrome)), ((grayscale)),paintings, sketches,nipples, skin spots, acnes, skin blemishes, bad anatomy,facing away, looking away,tilted head,lowres,bad anatomy,bad hands, text, error, missing fingers,extra digit, fewer digits, blurry,bad feet,cropped,poorly drawn hands,poorly drawn face,mutation,deformed,worst quality,low quality,normal quality,jpeg artifacts,signature,watermark,extra fingers,fewer digits,extra limbs,extra arms,extra legs,malformed limbs,fused fingers,too many fingers,long neck,cross-eyed,mutated hands,polar lowres,bad body,bad proportions, bad-picture-chill-75v" },
        {name: "sampler_index", default: "DPM++ SDE Karras"},
    ],
    info: "AI model for underwear 2 based on collection of images",
    endpoint:
      "https://aimodel.dev-metaverse.fun/get_response/v1",
    options: {
      width: { min: 1, max: 1024, default: 512, type: "int" },
      height: { min: 1, max: 1024, default: 1024, type: "int" },
      steps: { min: 10, max: 50, default: 20, type: "int" },
      cfg_scale: { min: 0, max: 20, default: 7, type: "float" },
      batch_size: { min: 1, max: 4, default: 1, type: "int" },
    },
  },
    {
    id: 2,
    name: "AI e-cigarette",
    type: "Stable diffusion",
    inputParams: [{ name: "prompt", default: "e-cigarette, technology,  luxury, frosted, solid , <lora:dianziyan (1):0.8>" },
      { name: "negative_prompt", default: "Embossed, ((bad construction)), words, text, ((distorted structure)), deformedeyes,((disfigured)),((badart)),((deformed)),((extra limbs)),(((duplicate))),((morbid)),((mutilated)),outof frame,extra fingers,mutated hands,poorly drawn eyes,((poorly drawnhands)),((poorlydrawn face)),(((mutation))),((ugly)),blurry,((badanatomy)),(((badproportions))),cloned face,bodyout of frame,out of frame,bad anatomy,gross proportions,(malformedlimbs),((missingarms)),((missinglegs)),(((extraarms))),((extralegs))),(fused fingers),(toomanyfingers),((longneck))),tiling,poorlydrawn,mutated,cross-eye,canvas frame,frame,weird colors,blurry" },
        {name: "sampler_index", default: "DPM++ SDE Karras"},
    ],
    info: "AI generation e-cigarette based on collection of images",
    endpoint:
      "https://aimodel.dev-metaverse.fun/get_response/v1",
    options: {
      width: { min: 1, max: 1024, default: 512, type: "int" },
      height: { min: 1, max: 1024, default: 512, type: "int" },
      steps: { min: 10, max: 50, default: 20, type: "int" },
      cfg_scale: { min: 0, max: 20, default: 7, type: "float" },
      batch_size: { min: 1, max: 4, default: 1, type: "int" },
    },
  },
  {
    id: 3,
    name: "AI children's helmet",
    type: "Stable diffusion",
    inputParams: [{ name: "prompt", default: "a cute boy , helmet , sitting on the sofa  ,smiling   <lora:helt3.27-000012:1>" },
      { name: "negative_prompt", default: "Embossed, ((bad construction)), words, text, ((distorted structure)), deformedeyes,((disfigured)),((badart)),((deformed)),((extra limbs)),(((duplicate))),((morbid)),((mutilated)),outof frame,extra fingers,mutated hands,poorly drawn eyes,((poorly drawnhands)),((poorlydrawn face)),(((mutation))),((ugly)),blurry,((badanatomy)),(((badproportions))),cloned face,bodyout of frame,out of frame,bad anatomy,gross proportions,(malformedlimbs),((missingarms)),((missinglegs)),(((extraarms))),((extralegs))),(fused fingers),(toomanyfingers),((longneck))),tiling,poorlydrawn,mutated,cross-eye,canvas frame,frame,weird colors,blurry" },
        {name: "sampler_index", default: "DPM++ SDE Karras"},
    ],
    info: "AI generated chidren's helmet based on collection of images",
    endpoint:
      "https://aimodel.dev-metaverse.fun/get_response/v1",
    options: {
      width: { min: 1, max: 1024, default: 512, type: "int" },
      height: { min: 1, max: 1024, default: 512, type: "int" },
      steps: { min: 10, max: 50, default: 20, type: "int" },
      cfg_scale: { min: 0, max: 20, default: 7, type: "float" },
      batch_size: { min: 1, max: 4, default: 1, type: "int" },
    },
  },
];
