<template>
  <div class="container">
    <div class="form-container">
      <h1>Paintpal</h1>
      <form @submit.prevent="handleSubmit">
        <div class="model-selector-group">
          <div class="model-selector">
            <label for="type" class="label">
              Type
              <select
                id="type"
                v-model="selectedType"
                @change="handleInitializedModel()"
              >
                <option v-for="item in typeValues" :key="item" :value="item">
                  {{ item }}
                </option>
              </select>
            </label>
          </div>
          <div class="model-selector">
            <label for="model" class="label">
              Model
              <select
                id="model"
                v-model="selectedModel"
                @change="handleInitializeValues()"
              >
                <option
                  v-for="model in models"
                  :key="model.name"
                  :value="model"
                >
                  {{ model.name }}
                </option>
              </select>
            </label>
          </div>
        </div>
        <fieldset class="input-group">
          <label
            v-for="inputText in selectedModel.inputParams"
            :key="inputText.id"
            class="prompt-container"
          >
            {{ inputText.name }}
            <textarea
              spellcheck
              v-model="values[`${inputText?.name}`]"
              type="text"
              :placeholder="inputText.default"
              :name="inputText.name"
            />
            <div class="helper-text-container">
              <small class="helper-text">
                {{ values[`${inputText?.name}`]?.length || 0 }} Characters
              </small>
            </div>
          </label>
        </fieldset>

        <fieldset class="slider-container">
          <label v-for="(val, key, index) in optionValues" :key="index">
            <legend>
              {{ key }}
            </legend>
            <Slider
              :step="options[key].type === 'int' ? 1 : -1 || 1"
              :min="options[key].min || 0"
              :max="options[key].max || 10"
              v-model="optionValues[key]"
              showTooltip="focus"
            />
          </label>
        </fieldset>

        <fieldset v-if="selectedModel.password" class="slider-container">
          <label>
            <legend>Password</legend>
            <input
              v-model="values[`password`]"
              placeholder="Password for this model"
              name="password"
              type="password"
              class="password"
            />
          </label>
        </fieldset>

        <div class="button-container">
          <button
            :disabled="
              loading ||
              this.isTextboxEmpty ||
              (selectedModel?.password &&
                selectedModel?.password !== values.password)
            "
          >
            <div class="button-loading-container" v-if="loading">
              <LoadingDots />
            </div>
            {{ loading ? "" : "generate" }}
          </button>
        </div>
      </form>

      <div v-if="result.length && !loading" class="result">
        <h3>Output:</h3>
        <img v-if="returnType === 'image'" :src="result" class="result-image" />
        <p v-else>{{ result }}</p>
      </div>
      <small
        class="result result--empty"
        v-if="!loading && result.length === 0"
      >
        "Please input some text in the text box for the model to generate
        content!"
      </small>
      <div class="result-loading-container">
        <LoadingPulse v-if="loading" />
      </div>
    </div>
    <div class="model-info-container">
      <ModelInfo :model="selectedModel" />
    </div>
  </div>
</template>

<script>
import { generateText } from "../../services/ModelService";
import ModelInfo from "../ModelInfo.vue";
import Models from "../../utils/models";
import LoadingDots from "../LoadingDots.vue";
import { useToast } from "vue-toastification";
import LoadingPulse from "../LoadingPulse.vue";
import Slider from "@vueform/slider";

export default {
  name: "Generator",
  components: { ModelInfo, LoadingDots, LoadingPulse, Slider },

  data() {
    return {
      values: {},
      selectedModel: Models[0],
      selectedType: Models[0].type,
      loading: false,
      result: "",
      returnType: "text",
    };
  },

  created() {
    this.handleInitializeValues();
  },

  computed: {
    isTextboxEmpty() {
      for (let key in this.values) {
        if (typeof this.values[key] !== String) return false;
        else if (this.values[key].replace(" ", "").length === 0) return true;
      }
      return false;
    },

    options() {
      return this.selectedModel.options;
    },

    optionValues() {
      var values = {};
      for (let key in this.selectedModel.options) {
        values[key] = this.selectedModel.options[key].default || 1;
      }
      return values;
    },

    typeValues() {
      return new Set(Models.map((model) => model.type));
    },

    models() {
      let matchingModels = [];
      Models.forEach((model) => {
        if (model.type === this.selectedType) matchingModels.push(model);
      });
      return matchingModels;
    },

    passwordCorrect() {
      console.log(this.selectedModel, this.values);
      if (!this.selectedModel.password) return true;
      else if (this.selectedModel.password === this.values.password)
        return true;
      else return false;
    },
  },

  methods: {
    handleOpenDialog() {
      this.$emit("toggle-dialog", true);
    },

    handleInitializedModel() {
      this.selectedModel = this.models[0];
      this.handleInitializeValues();
    },

    handleInitializeValues() {
      this.values = {};

      this.selectedModel.inputParams.forEach((item) => {
        this.values[`${item.name}`] = item.default;
      });
    },

    async handleSubmit() {
      this.loading = true;
      try {
        const { result, returnType } = await generateText({
          ...this.values,
          endpoint: this.selectedModel.endpoint,
          options: this.optionValues,
        });
        if (returnType) this.returnType = returnType;
        else this.returnType = "text";
        this.result = result[0];
      } catch (err) {
        const toast = useToast();
        this.result = "";
        toast.error("Server Call Failed");
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
@import "./index.css";
</style>

<style src="@vueform/slider/themes/default.css"></style>
